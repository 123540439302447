import {
  PlatformSearch_ArticleSearchItemFragment,
  PlatformSearch_OfferingSearchItemFragment,
  PlatformSearch_ProDirectorySearchItemFragment,
} from '../../../../__generated__/types'
import {LinkService} from '../../../core/i18n/link.service'
import {ArticlePreview} from '../../../core/content/types/article.types'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'

export const searchTextQueryParam = 'q'
export const tabQueryParam = 't'

export type OfferingItem = PlatformSearch_OfferingSearchItemFragment & {searchType: 'Offering'}
export type OfferingSearchItems = OfferingItem[]
export type ArticleItem = PlatformSearch_ArticleSearchItemFragment
export type ArticlePreviewItem = ArticlePreview & {preview: string} & {searchType: 'Article'}
export type ArticleSearchItems = ArticleItem[]
export type ProDirectoryFilter = {searchText?: string; geoLocation?: GeolocationCoordinates}
export type PlatformSearchItems = (
  | PlatformSearch_ArticleSearchItemFragment
  | PlatformSearch_OfferingSearchItemFragment
  | PlatformSearch_ProDirectorySearchItemFragment
)[]
export type ProDirectorySummaryItem = {count: number; searchType: 'Pro'}

export interface Results<
  T extends OfferingSearchItems | ArticleSearchItems | ProDirectoryFilter | PlatformSearchItems,
> {
  searchText?: string
  results: T
}

export function emptyResult<
  T extends OfferingSearchItems | ArticleSearchItems | ProDirectoryFilter | PlatformSearchItems,
>(): Results<T> {
  return {
    results: [] as never,
  }
}

export function toArticlePreview(a: ArticleItem, linkService: LinkService): ArticlePreviewItem {
  return {
    searchType: 'Article',
    title: a.title,
    preview: a.preview ?? '',
    slug: a.slug,
    path: linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_PAGE, {routeParams: {pageSlug: a.slug}}),
    topic: a.topic?.breadcrumbTitle || a.topic?.title,
    headerImage: a.bannerImage
      ? {
          title: a.bannerImage.title,
          url: a.bannerImage.file,
        }
      : undefined,
  }
}

export const AllSearchTabs = ['offerings', 'articles', 'pro-directory', 'all'] as const
export type Tab = (typeof AllSearchTabs)[number]

export type TabSearch = {
  [key in Tab]: () => void
}
